import React from "react";
import "./Header.css";
import { googleLogout } from "@react-oauth/google";

const Header = ({ isValid }) => {
  const handleLogout = () => {
    googleLogout();

    localStorage.removeItem('token');
    localStorage.removeItem('user');

    // Redirect to the login page
    window.location.href = '/login';
  };

  return (
    <header className="header">
      <div className="logo">Payment Reconciliation</div>
      <nav className="nav">
        {isValid ? <ul>
          <li>
            <a href="/">Uploads</a>
          </li>
          <li>
            <a href="/transactions">Transactions</a>
          </li>
          <li style={{ cursor: "pointer" }} onClick={handleLogout}>Logout</li>
        </ul> : <></>}
      </nav>
    </header>
  );
};

export default Header;

import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const userInfo = await fetch(
                'https://www.googleapis.com/oauth2/v3/userinfo',
                {
                    headers: {
                        Authorization: `Bearer ${tokenResponse.access_token}`,
                    },
                }
            ).then(res => res.json());

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_KEY}/verify-email`,
                    {
                        params: {
                            email: userInfo?.email,
                        },
                    }
                );
                if (response?.data?.valid) {
                    localStorage.setItem('user', JSON.stringify(userInfo));
                    localStorage.setItem('token', tokenResponse.access_token);
                    window.location.href = '/';
                } else {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    toast.error("Invalid User");
                }
            } catch (error) {
                console.error("Error fetching transactions:", error);
            }
        },
        onError: errorResponse => console.log(errorResponse),
    });

    return (
        <>
            <button onClick={() => login()}><img src="icons_gmail.svg" width="29" height="28"
            />Login With Gmail
            </button>
            <ToastContainer />
        </>
    );
};

export default Login;

import React, { useRef } from "react";
import axios from "axios";
import "./FileUpload.css";

export const FileUpload = ({ isUploading, setIsUploading }) => {
  const fileInputRef = useRef(null);

  const { name: userName } = JSON.parse(localStorage.getItem('user')) || {};

  const handleFileChange = async (event) => {
    const file = event.target.files?.[0];
    if (
      file &&
      (file.type === "text/csv" ||
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      if (file.size <= 15 * 1024 * 1024) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("uploadedBy", userName || '');
        formData.append("uploadedFrom", "PR_DASHBOARD");
        setIsUploading(true);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_KEY}/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: process.env.REACT_APP_API_AUTH
              },
            }
          );
          console.log("File uploaded successfully:", response.data);
        } catch (error) {
          console.error("Error uploading file:", error);
        } finally {
          setIsUploading(false);
          if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Clear the file input
          }
        }
      } else {
        alert("File size must be less than 15MB");
      }
    } else {
      alert("Please select a valid CSV or XLSX file");
    }
  };

  return (
    <div className="upload-container">
      <input
        id="fileInput"
        type="file"
        accept=".csv, .xlsx"
        onChange={handleFileChange}
        className="upload-input"
        ref={fileInputRef}
      />
      {isUploading && <p className="upload-processing">Processing...</p>}
      <label className="upload-label" htmlFor="fileInput">
        <strong>Note*:</strong> Choose a CSV or XLSX file
      </label>
    </div>
  );
};

import axios from "axios";
import { useEffect, useState } from "react";

export const BankSelect = ({ onBankChange, selectedBank }) => {
    const [bankNames, setBankNames] = useState([]);

    useEffect(() => {
        const fetchBankNames = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_KEY}/get-unique-banks`, {
                    headers: {
                        Authorization: process.env.REACT_APP_API_AUTH,
                    },
                });
                setBankNames(response?.data);
            } catch (error) {
                console.error('Error fetching bank names:', error);
            }
        };

        fetchBankNames();
    }, []);

    return (
        <select value={selectedBank} onChange={(e) => onBankChange(e.target.value)}>
            <option value="">Select Bank</option>
            {bankNames.map((bankName, index) => (
                <option key={index} value={bankName}>
                    {bankName}
                </option>
            ))}
        </select>
    );
};

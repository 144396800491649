import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./UploadList.css";
import downloadIcon from "../localpublic/downloadIt.svg";
import classNames from "classnames";

export const UploadList = ({ isUploading }) => {
  const [uploads, setUploads] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    fetchUploads();
  }, [currentPage, isUploading]);

  const fetchUploads = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/get-all-uploads`,
        {
          params: {
            page: currentPage,
          },
          headers: {
            Authorization: process.env.REACT_APP_API_AUTH
          }
        }
      );
      setUploads(response.data.uploads);
      setPageCount(response.data.pageCount);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching uploads:", error);
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleDownload = (filePath) => {
    // Construct the full URL for the file
    const fileUrl = `${process.env.REACT_APP_S3_BUCKET_ENDPOINT}${filePath}`;

    // Create an invisible anchor element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = filePath.split("/").pop(); // Use the filename from the filePath
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  function formatSeconds(seconds) {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hrs} hrs ${mins} min ${secs} sec`;
  }

  return (
    <div className="container">
      <div className="display-flex-heading">
        <h2>Transaction File Uploads({totalRecords})</h2> <span onClick={() => handleDownload(process.env.REACT_APP_SAMPLE_SHEET_ENDPOINT)}>Download Sample</span>
      </div>
      <div className="table-responsive">
        <table className="uploads-table">
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Uploaded By</th>
              <th>Format</th>
              <th>Status</th>
              <th className="time-wide">Date</th>
              <th className="time-wide">Time Taken</th>
              <th>Uploaded</th>
              <th>Success Count</th>
              <th>Duplicate</th>
              <th>Duplicate Count</th>
              <th>Error</th>
              <th>Error Count</th>
              <th>Total Count</th>
            </tr>
          </thead>
          <tbody>
            {uploads?.map((upload, index) => {
              const createdDateObject = new Date(upload?.createdAt);
              const createdDate = createdDateObject?.toLocaleString(
                "en-US",
                options
              );
              const srNo = index + 1 + currentPage * 20;
              const timeTakenInUpload = formatSeconds(upload?.timeTaken);
              return (
                <tr key={upload._id}>
                  <td>{srNo}</td>
                  <td>{upload?.uploadedBy ? upload?.uploadedBy : "-"}</td>
                  <td>{upload?.formatType ? upload?.formatType : "-"}</td>
                  <td className="badge-td">
                    <span
                      className={classNames({
                        badge: true,
                        "badge-pending":
                          upload?.status === "PENDING" ||
                          upload?.status === "PROCESSING",
                        "badge-error": upload?.status === "ERROR",
                        "badge-success": upload?.status === "COMPLETED",
                      })}
                    >
                      {upload?.status}
                    </span>
                  </td>
                  <td className="time-wide">
                    {upload?.createdAt ? createdDate : "-"}
                  </td>
                  <td className="time-wide">
                    {upload?.timeTaken ? timeTakenInUpload : "-"}
                  </td>
                  <td>
                    {upload?.uploadedFilePath ? (
                      <img
                        alt="download_icon"
                        src={downloadIcon}
                        onClick={() => handleDownload(upload?.uploadedFilePath)}
                        className="cursorPointer"
                      />
                    ) : (
                      <img
                        alt="download_icon"
                        src={downloadIcon}
                        className="disabledIcons"
                      />
                    )}
                  </td>
                  <td>
                    {upload?.successRecords ? upload?.successRecords : "-"}
                  </td>
                  <td>
                    {upload?.duplicateFilePath ? (
                      <img
                        alt="download_icon"
                        src={downloadIcon}
                        onClick={() =>
                          handleDownload(upload?.duplicateFilePath)
                        }
                        className="cursorPointer"
                      />
                    ) : (
                      <img
                        alt="download_icon"
                        src={downloadIcon}
                        className="disabledIcons"
                      />
                    )}
                  </td>
                  <td>
                    {upload?.duplicateRecords ? upload?.duplicateRecords : "-"}
                  </td>
                  <td>
                    {upload?.errorFilePath ? (
                      <img
                        alt="download_icon"
                        src={downloadIcon}
                        onClick={() => handleDownload(upload?.errorFilePath)}
                        className="cursorPointer"
                      />
                    ) : (
                      <img
                        alt="download_icon"
                        src={downloadIcon}
                        className="disabledIcons"
                      />
                    )}
                  </td>
                  <td>{upload?.errorRecords ? upload?.errorRecords : "-"}</td>
                  <td>{upload?.totalRecords ? upload?.totalRecords : "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};

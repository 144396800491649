// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1 1;
  padding: 10px;
}

.flex-login {
  flex: 1 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,yBAAyB;EACzB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,SAAO;EACP,aAAa;AACf;;AAEA;EACE,SAAO;EACP,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".App {\n  font-family: 'Arial', sans-serif;\n  background-color: #f0f2f5;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\n.main-content {\n  flex: 1;\n  padding: 10px;\n}\n\n.flex-login {\n  flex: 1;\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import "./UploadList.css";

const Timeline = ({ history }) => {
  return (
    <div className="timeline">
      {history?.map((entry, index) => (
        <div key={index} className="timeline-entry">
          <div className="timeline-content">
            <p>
              <strong>Status:</strong> {entry?.status || "-"}
            </p>
            <p>
              <strong>Updated By:</strong> {entry?.updatedBy || ""}
            </p>
            <p>
              <strong>Updated At:</strong>{" "}
              {new Date(entry?.createdAt)?.toLocaleString("en-IN", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;

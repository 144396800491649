import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BankSelect } from './BankSelect';

const FilterWrapper = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedBank, setSelectedBank] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    const handleQueryParams = (query) => {
        const params = new URLSearchParams(query);
        setSearchQuery(params.get('searchQuery') || '');
        setSelectedBank(params.get('selectedBank') || '');
        setSelectedType(params.get('selectedType') || '');
        setSelectedStatus(params.get('selectedStatus') || '');
    };

    useEffect(() => {
        handleQueryParams(location.search);
    }, [location.search]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleTypeChange = (e) => {
        setSelectedType(e.target.value);
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    const handleButtonClick = () => {
        const query = {
            searchQuery,
            selectedBank,
            selectedType,
            selectedStatus,
        };

        const cleanedQuery = Object.fromEntries(
            Object.entries(query).filter(([_, v]) => v)
        );

        const queryString = new URLSearchParams(cleanedQuery).toString();
        navigate(`/transactions?${queryString}`);
    };

    return (
        <div className="filter-wrapper">
            <input
                type="text"
                className="search-input"
                placeholder="Search by UTR Number"
                value={searchQuery}
                onChange={handleSearchChange}
            />
            <BankSelect onBankChange={setSelectedBank} selectedBank={selectedBank} />
            <select value={selectedType} onChange={handleTypeChange}>
                <option value="">Select Type</option>
                <option value="CR">Credit</option>
                <option value="DR">Debit</option>
            </select>
            <select value={selectedStatus} onChange={handleStatusChange}>
                <option value="">Select Status</option>
                <option value="Consumed">Consumed</option>
                <option value="Not Consumed">Not Consumed</option>
            </select>
            <div className='btn-wrapper'>
                <button onClick={handleButtonClick} className='global-btn'>Apply Filters</button>
                <div className="resetFormdbtn box-outline-shadow d-none d-md-inline-block" onClick={() => navigate(`/transactions`)}>
                    <img src="https://d15finj.tractorfirst.com/assets/images/reset-icon.svg" width="18" height="18" className="img-fluid" alt="reset icon" />
                </div>
            </div>
        </div>
    );
};

export default FilterWrapper;

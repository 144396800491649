import React, { useEffect, useState } from "react";
import "./App.css";
import { FileUpload } from "./components/FileUpload";
import { UploadList } from "./components/UploadList";
import { TransactionList } from "./components/TransactionList";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";

const App = () => {
  const [isValid, setIsValid] = useState(null);
  const token = localStorage.getItem('token');
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const validateToken = async (token) => {
      try {
        const response = await fetch(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${token}`);
        const data = await response.json();

        if (data && data.audience === process.env.REACT_APP_GOOGLE_CLIENT_ID) {
          setIsValid(true);
        } else {
          setIsValid(false);
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
      } catch (error) {
        console.error("Token validation failed:", error);
        setIsValid(false);
      }
    };

    if (token) {
      validateToken(token);
    } else {
      setIsValid(false);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
  }, [token]);

  if (isValid === null) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Header isValid={isValid} />
        {isValid ? <main className="main-content">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <FileUpload isUploading={isUploading} setIsUploading={setIsUploading} />
                  <UploadList isUploading={isUploading} />
                </>
              }
            />
            <Route
              path="/transactions"
              element={
                <TransactionList />
              }
            />
          </Routes>
        </main> : <main className="flex-login">
          <div className="login-main">
            <div className="login-inner">
              <span><img src="295128.png" height={80} width={80} /></span>
              <p>Welcome to Renconcile Payments</p>
              <Login />
            </div>
          </div>
        </main>}

      </div>
    </Router>
  );
};

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-container {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin: 20px auto;
    padding: 20px;
    border: 2px dashed #ccc;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.upload-label {
    margin-bottom: 10px;
    font-size: 15px;
    color: #555;
    cursor: pointer;
    margin-left: auto;
    pointer-events: none;
}

.upload-input {
    padding: 10px;
    font-size: 15px;
}

.upload-processing {
    margin-top: 10px;
    font-size: 15px;
    color: #ff4500;
}`, "",{"version":3,"sources":["webpack://./src/components/FileUpload.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,qBAAqB;IACrB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,uCAAuC;AAC3C;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".upload-container {\n    display: flex;\n    justify-content: flex-start;\n    align-items: baseline;\n    margin: 20px auto;\n    padding: 20px;\n    border: 2px dashed #ccc;\n    border-radius: 10px;\n    background-color: #fff;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.upload-label {\n    margin-bottom: 10px;\n    font-size: 15px;\n    color: #555;\n    cursor: pointer;\n    margin-left: auto;\n    pointer-events: none;\n}\n\n.upload-input {\n    padding: 10px;\n    font-size: 15px;\n}\n\n.upload-processing {\n    margin-top: 10px;\n    font-size: 15px;\n    color: #ff4500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

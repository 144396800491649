import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./UploadList.css";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import Timeline from "./Timeline";
import Highlight from "./Highlight";
import FilterWrapper from "./FilterWrapper";
import { useLocation } from "react-router-dom";

export const TransactionList = () => {
  const [transactions, setTransactions] = useState([]);
  const [query, setQuery] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const location = useLocation();

  useEffect(() => {
    fetchTransactions();
  }, [currentPage, location.search]);

  const fetchTransactions = async () => {
    try {
      const params = new URLSearchParams(location.search);
      const queryObject = {};

      params.forEach((value, key) => {
        queryObject[key] = value;
      });
      setQuery(queryObject ?? {});
      const response = await axios.get(
        `${process.env.REACT_APP_API_KEY}/get-all-transactions`,
        {
          params: {
            page: currentPage,
            ...queryObject
          },
          headers: {
            Authorization: process.env.REACT_APP_API_AUTH,
          }
        }
      );
      setTransactions(response?.data?.transactions);
      setPageCount(response?.data?.pageCount);
      setCount({
        total: response?.data?.totalRecords || 0,
        consumed: response?.data?.consumed || 0,
        notConsumed: response?.data?.notConsumed || 0,
      });
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const handlePageClick = (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <div className="container">
      <h2>Transactions</h2>
      <div className="box-card-wrapper">
        <div className="box-card-item">
          <div className="boxinner">
            <h3>Total</h3>
            <span>
              {count?.total}
            </span>
          </div>
        </div>

        <div className="box-card-item">
          <div className="boxinner">
            <h3>Consumed</h3>
            <span>
              {count?.consumed}
            </span>
          </div>
        </div>

        <div className="box-card-item">
          <div className="boxinner">
            <h3>Not Consumed</h3>
            <span>
              {count?.notConsumed}
            </span>
          </div>
        </div>
      </div>
      <FilterWrapper />
      <div className="table-responsive">
        <table className="uploads-table">
          <thead>
            <tr>
              <th className="text-center">UTR Number</th>
              <th className="text-center">Bank Name</th>
              <th className="text-center">Amount</th>
              <th className="text-center">Date</th>
              <th className="text-center">Type</th>
              <th className="text-center">Status</th>
              <th className="text-center">Consumed By</th>
              <th className="text-center">Consumed At</th>
              <th className="text-center">Consumed History</th>
            </tr>
          </thead>
          <tbody>
            {transactions?.map((transaction) => {
              const formatAmount = new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              });
              const sanitizedAmount = transaction?.paymentAmount
                ? formatAmount.format(transaction?.paymentAmount)
                : "-";
              return (
                <tr key={transaction._id}>
                  <td className="text-center">
                    <Highlight
                      text={transaction.utrNumber}
                      highlight={query?.searchQuery || ''}
                    />
                  </td>
                  <td className="text-center">
                    {transaction.bankName ? transaction.bankName : "-"}
                  </td>
                  <td className="text-center">{sanitizedAmount}</td>
                  <td className="text-center time-wide">
                    {transaction.dateOfTransaction
                      ? new Date(transaction.dateOfTransaction).toLocaleString(
                        "en-IN",
                        options
                      )
                      : "-"}
                  </td>
                  <td className="text-center">
                    {transaction.paymentType === "CR"
                      ? "Credit"
                      : transaction.paymentType === "DR"
                        ? "Debit"
                        : "-"}
                  </td>
                  <td className="badge-td text-center">
                    <span
                      className={classNames({
                        badge: true,
                        "badge-error": transaction?.status === "Not Consumed",
                        "badge-success": transaction?.status === "Consumed",
                      })}
                    >
                      {transaction?.status ? transaction?.status : "-"}
                    </span>
                  </td>
                  <td className="text-center">
                    {transaction.consumedBy ? transaction.consumedBy : "-"}
                  </td>
                  <td className="text-center">
                    {transaction.consumedOn
                      ? new Date(transaction.consumedOn).toLocaleString(
                        "en-IN",
                        options
                      )
                      : "-"}
                  </td>
                  <td className="text-center">
                    {transaction.consumedHistory?.length ? (
                      <>
                        {" "}
                        <button id="viewLogsButton">View</button>
                        <Tooltip
                          anchorId="viewLogsButton"
                          place="right"
                          effect="solid"
                          className="tooltip"
                        >
                          <Timeline history={transaction.consumedHistory} />
                        </Tooltip>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
};
